<template>
  <div class="parcels-wrapper__orders">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info">
          <span class="grid-heading">
            <h1>{{ $t('Customers') }}</h1>
            <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <div class="label">
              <button class="button" style="min-width: 115px;" @click.prevent="Create">{{ $t('Create') }}</button>
            </div>
          </div>
          <div class="grid-search">
            <input type="text" @keyup.enter="Search" v-model="search.query" :placeholder="$t('Search by name, phone or email')" spellcheck="false">
          </div>
        </div>
        <table class="list table odd-even">
          <tbody>
            <tr :data-id="item.id" :class="['campaign-list__row clickable']" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" v-for="item in list.items" :key="item.id">
              <td>
                <div class="flex-row">
                  {{ item.id }}
                </div>
              </td>
              <td>
                <div class="checkbox-toggle mini">
                  <input type="checkbox" :checked="item.auto_lock_orders" @input="ToggleSwitch(item, $event)">
                  <span class="toggle" />
                </div>
              </td>
              <td>
                <div class="flex-row">
                  {{ TitleCase(item.first_name + ' ' + item.last_name) }}
                </div>
              </td>
              <td>
                <div class="flex-column" v-if="item.lookup.phone.length">
                  <div :data-id="lookup.id" v-for="lookup in item.lookup.phone" :key="lookup.id">
                    {{ lookup.check_value }}
                  </div>
                </div>
                <div class="flex-column" v-else>-</div>
              </td>
              <td>
                <div class="flex-column" v-if="item.lookup.email.length">
                  <div :data-id="lookup.id" v-for="lookup in item.lookup.email" :key="lookup.id">
                    {{ lookup.check_value }}
                  </div>
                </div>
                <div class="flex-column" v-else>-</div>
              </td>
              <td>
                <div class="flex-column">
                  {{ NumberFormat(item.note_count) }}
                </div>
              </td>
              <td>
                <div class="flex-row" style="white-space: nowrap;">
                  <span :class="['state', (modal.customer.options.find(option => option.code == item.note_type) || {}).color]">
                    {{ $t(Capitalize(item.note_type)) }}
                  </span>
                </div>
              </td>
              <td style="max-width: 350px;">
                <div class="flex-row" style="white-space: pre-line;">
                  {{ item.note_value || '-' }}
                </div>
              </td>
              <td class="actions" @mouseenter="SetBackground" @mouseleave="SetBackground">
                <a class="icon dots" @click.prevent="" href="">
                  <ul class="item-actions">
                    <li><a @click.prevent="Edit(item.id)" href="">{{ $t('Edit') }}</a></li>
                    <li><a @click.prevent="Delete(item.id)" href="" class="error">{{ $t('Delete') }}</a></li>
                  </ul>
                </a>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>{{ $t('ID') }}</th>
              <th>{{ $t('Auto-lock') }}</th>
              <th>{{ $t('Name') }}</th>
              <th>{{ $t('Phone') }}</th>
              <th>{{ $t('Email') }}</th>
              <th>{{ $t('Notes') }}</th>
              <th>{{ $t('Latest') + ' ' + $t('Type').toLowerCase() }}</th>
              <th>{{ $t('Latest') + ' ' + $t('Note').toLowerCase() }}</th>
              <th class="edit" />
            </tr>
          </thead>
        </table>
        <div class="grid-pagination shadow sticky bottom" :style="{marginTop: list.items.length && '2px'}">
          <div class="page-navigation">
            <div class="page-turn prev disabled" @click="PageController(false)"></div>
            <div class="page-number">
              <label class="page-number-current">
                <input type="number" min="1" :max="page.last" :value="page.current" @blur="PageNavigator" @keydown="PageNavigator">
                <span class="placeholder">{{NumberFormat(page.current)}}</span>
              </label>
              <span class="page-number-separator">/</span>
              <div class="page-number-last">{{NumberFormat(page.last)}}</div>
            </div>
            <div class="page-turn next" :class="{disabled: page.last == 1}" @click="PageController(true)"></div>
          </div>
        </div>
      </div>
    </div>

    <Modal modal="customer" :value="modal.customer.open" :title="$t(modal.customer.mode == 'create' ? 'New customer' : 'Customer') + (modal.customer.mode == 'edit' ? ` #${customer.id}` : '')">
      <div v-if="modal.customer.open">
        <div class="modal-header">
          <div class="modal-tabs__body-content" style="width: 100%; padding: 25px 0;">
            <form @submit.prevent ref="customer_form">
              <button type="submit" ref="customer_submit" hidden />
              <div class="flex-group" style="display: flex;">
                <div class="flex-column" style="width: calc(50% - 15px);">
                  <div class="flex-row">
                    <div class="label required" style="width: calc(100% - 30px);">
                      <span class="label-text">{{ $t('First name') }}</span>
                      <input class="v-input" ref="customer_first_name" v-model="customer.first_name" @keydown.enter="$event.target.blur(), $event.preventDefault()" @blur="HandleName('first')" required tabindex="1">
                    </div>
                    <div class="label required" style="width: calc(100% - 15px); margin-left: 30px;">
                      <span class="label-text">{{ $t('Last name') }}</span>
                      <input class="v-input" ref="customer_last_name" v-model="customer.last_name" @keydown.enter="$event.target.blur(), $event.preventDefault()" @blur="HandleName('last')" required tabindex="2">
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="label" style="width: calc(30% - 15px);">
                      <span class="label-text">{{ $t('Type') }}</span>
                      <v-select name="note_type" :value="modal.customer.options.find(o => o.code == modal.customer.type)" @input="modal.customer.type = $event.code" :options="modal.customer.options.map(o => {o.label = $t(Capitalize(o.code)); return o;})" :clearable="false" :tabindex="5">
                        <template v-slot:selected-option="option">
                          <div class="truncate">
                            <span :class="['state', option.color]">{{ $t(option.label) }}</span>
                          </div>
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="truncate">
                            <span :class="['state', option.color]">{{ $t(option.label) }}</span>
                          </div>
                        </template>
                      </v-select>
                    </div>
                    <div class="label" style="width: calc(70% - 15px); margin-left: 30px;">
                      <span class="label-text">{{ $t('Note') }}</span>
                      <div class="v-wrapper border" style="min-height: 34px; max-height: 144px; overflow-y: auto; display: block; padding: 4px 7px; cursor: text;" @click="HandleTextArea">
                        <!-- <textarea class="v-textarea" ref="customer_note" style="width: 100%; max-height: 144px; border: 0; resize: none;" :value="modal.customer.note" @input="FitToContent" @blur="FormatText" /> -->
                        <div class="v-wrapper-input-button icon plus" style="top: -3px; right: -5px; position: sticky; float: right; margin: -3px -7px -4px 7px;" @click="AddNote" />
                        <span ref="customer_note" style="outline: none; line-height: 1.5;" @keydown="HandleTextArea" @input="modal.customer.note = FormatText($event.target.innerHTML)" @blur="$event.target.innerHTML = FormatText($event.target.innerHTML)" @paste.prevent="HandlePaste" contenteditable="true" tabindex="6" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-column" style="width: calc(50% - 15px); margin-left: 30px;">
                  <div class="flex-row">
                    <div class="label required" style="width: calc(100% - 30px);">
                      <span class="label-text">{{ $t('Phone') }}</span>
                      <div class="v-input-list border">
                        <div class="v-wrapper-input-button icon plus" @click.prevent="AddLookup('phone')" />
                        <input type="tel" class="v-input" ref="customer_phone" :pattern="String(modal.customer.regex.phone).replace(/\//g, '')" v-model="modal.customer.phone" @keydown.enter="$event.target.blur(), $event.preventDefault(), AddLookup('phone')" @blur="HandleBlur('phone')" required tabindex="3">
                        <ul class="v-list">
                          <li class="v-list-item" v-for="(item, index) of customer.lookup.filter(item => item.check_type == 'tlf')" :key="index">
                            <div>{{ item.check_value }}</div>
                            <div class="v-list-item-button icon minus" @click.prevent="RemoveLookup('phone', index)" />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="label required" style="width: calc(100% - 15px); margin-left: 30px;">
                      <span class="label-text">{{ $t('Email') }}</span>
                      <div class="v-input-list border">
                        <div class="v-wrapper-input-button icon plus" @click.prevent="AddLookup('email')" />
                        <input type="email" class="v-input" ref="customer_email" :pattern="String(modal.customer.regex.email).replace(/\//g, '')" v-model="modal.customer.email" @keydown.enter="$event.target.blur(), $event.preventDefault(), AddLookup('email')" @blur="HandleBlur('email')" required tabindex="4">
                        <ul class="v-list">
                          <li class="v-list-item" v-for="(item, index) of customer.lookup.filter(item => item.check_type == 'mal')" :key="index">
                            <div>{{ item.check_value }}</div>
                            <div class="v-list-item-button icon minus" @click.prevent="RemoveLookup('email', index)" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-content">
          <div class="modal-tabs">
            <ul class="modal-tabs__head">
              <li :class="['modal-tabs__head-item', {active: modal.customer.tab == 'notes'}]" @click="modal.customer.tab = 'notes'">
                <span>{{ $t('Notes') }}</span>
              </li>
              <li :class="['modal-tabs__head-item', {active: modal.customer.tab == 'orders'}]" v-if="modal.customer.mode == 'edit'" @click="GetOrders">
                <span>{{ $t('Orders') }}</span>
              </li>
            </ul>
            <ul class="modal-tabs__body" style="padding-left: 0px; padding-right: 0px;">
              <li class="modal-tabs__body-content padding" v-show="modal.customer.tab == 'notes'">
                <table class="list table odd-even">
                  <tbody>
                    <tr class="list__row" :key="index" v-for="(note, index) in customer.note">
                      <td>
                        <div :class="['flex-row state', modal.customer.options.find(o => o.code == note.note_type).color]">
                          {{ $t(Capitalize(note.note_type)) }}
                        </div>
                      </td>
                      <td>
                        <div class="flex-row" style="white-space: pre-line;">
                          {{ note.note_value }}
                        </div>
                      </td>
                      <td>
                        <div class="flex-row">
                          {{ !note.res_user_id ? DateFormat(note.created_at).replace(/(,?)\s\d{2}:\d{2}:\d{2}/, '') : DateFormat(note.created_at) }}
                        </div>
                      </td>
                      <td>
                        <div class="flex-row">
                          {{ TitleCase(note.created_by) }}
                        </div>
                      </td>
                      <td v-if="customer.note.some(note => !note.res_user_id)">
                        <div class="label" style="margin: -5px 0;" v-if="!note.res_user_id">
                          <div class="v-wrapper-input-button icon minus" style="margin-left: auto;" @click.prevent="RemoveNote(index)" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th>{{ $t('Type') }}</th>
                      <th>{{ $t('Note') }}</th>
                      <th>{{ $t('Created') }}</th>
                      <th>{{ $t('Created by') }}</th>
                      <th v-if="customer.note.some(note => !note.res_user_id)" />
                    </tr>
                  </thead>
                </table>
              </li>
              <li class="modal-tabs__body-content padding" v-show="modal.customer.tab == 'orders'" v-if="modal.customer.mode == 'edit'">
                <table class="list table odd-even">
                  <tbody>
                    <tr :data-id="order.id" class="list__row" :key="index" v-for="(order, index) in customer.order">
                      <td>
                        <div class="flex-row" v-html="Hyperlink({
                          href: ParcelOrder(order.id), target: '_blank', text: order.company_id + ':' + order.increment_id
                        })" />
                      </td>
                      <td>
                        <div class="flex-row">
                          {{ GetCompanyById(order.company_id) }}
                        </div>
                      </td>
                      <td>
                        <div class="flex-row">
                          <Flag :code="order.webshop.country" size="small" :title="LanguageName(order.webshop.language)" />
                          <span style="margin-left: 10px;" v-html="Hyperlink({href: order.webshop.url, target: '_blank', text: order.webshop.url.replace('https://', ''), title: LanguageName(order.webshop.language)})" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th>{{ $t('Order') + ' ID' }}</th>
                      <th>{{ $t('Company') }}</th>
                      <th>{{ $t('Webshop') }}</th>
                    </tr>
                  </thead>
                </table>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <div class="label" style="flex-direction: row; align-items: center; margin-right: auto;">
              <div class="checkbox-toggle">
                <input type="checkbox" :checked="customer.auto_lock_orders" @input="ToggleSwitch(customer, $event)" tabindex="7">
                <span class="toggle" />
              </div>
              <span style="margin-left: 10px;">{{ $t('Auto-lock') }}</span>
            </div>
            <button class="button green" @click.prevent="Submit" tabindex="8">{{ $t(modal.customer.mode == 'create' ? 'Create' : 'Save') }}</button>
            <button class="button red" @click.prevent="Delete(customer.id)" v-if="modal.customer.mode == 'edit'" tabindex="9">{{ $t(`Delete`) }}</button>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { Config }                 from '@/helpers/Config';
  import { Tool }                   from '@/helpers/Tool';
  import { BPA }                    from '@/helpers/BPA';
  import Modal                      from '@/components/snippets/Modal';
  import Flag                       from '@/components/snippets/Flag';

  export default {
    name: 'ParcelsCustomersList',
    mixins: [
      TableElementsBehaviour, 
      Config,
      Tool,
      BPA
    ],
    components: {
      Modal,
      Flag
    },
    data () {
      return {
        dev: Config.dev,
        loading: true,
        clickables: [],
        companies: [],
        clipboard: null,
        cached: {},
        entries: 0,
        page: {
          current: 1,
          number: 1,
          last: 1
        },
        search: {
          initial: null,
          input: null,
          query: '',
        },
        list: {
          items: [],
        },
        customer: {
          auto_lock_orders: false,
          first_name: '',
          last_name: '',
          lookup: [],
          order: [],
          note: [],
          data: {}
        },
        modal: {
          customer: {
            open: false,
            mode: 'create',
            tab: 'notes',
            phone: '',
            email: '',
            note: '',
            type: 'info',
            options: [
              {code: 'info', label: 'Info', color: 'blue'},
              {code: 'praise', label: 'Praise', color: 'green'},
              {code: 'advice', label: 'Advice', color: 'yellow'},
              {code: 'warning', label: 'Warning', color: 'red'}
            ],
            regex: {
              phone: /^(\+?)\d{8,}$/, 
              email: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/
            },
            lookup: type => type.length == 3 ? type == 'tlf' ? 'phone' : 'email' : type == 'phone' ? 'tlf' : 'mal'
          },
          auto_lock: {
            open: false
          }
        }
      }
    },
    created() {},
    async mounted() {
      this.clipboard = await Tool.PasteFromClipboard(true);
      this.user = JSON.parse(BPA.storage.getItem('user'));

      BPA.api.Companies('GET').forEach(company => {
        this.companies[company.id] = company.name;
      });

      this.$eventHub.$on('CloseModal', (modal_name) => {
        let modal = this.modal[modal_name];
        if (modal) modal.open = false;
        if (modal_name == 'customer') {
          delete this.customer.id;
          this.customer = {
            ...this.customer,
            ...{
              auto_lock_orders: false,
              first_name: '',
              last_name: '',
              lookup: [],
              order: [],
              note: [],
              data: {}
            }
          };
          this.modal.customer = {
            ...this.modal.customer, 
            ...{
              tab: 'notes',
              phone: '',
              email: '',
              note: '',
              type: 'info'
            }
          };
          this.cached.customer = {};
          BPA.cache.session({name: this.$options.name, set: {customer: {}}});
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].classList.contains('selected')) {
              this.clickables[i].classList.remove('selected');
            }
          }
        }
      });

      if (this.clipboard) {
        let clipboard = JSON.parse(this.clipboard || '{}');
        if (clipboard.create) {
          clipboard = clipboard.create;
          let items = await BPA.api.SearchCustomers({query: clipboard.first_name}).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok) return;
            return response.result.result;
          }).catch(e => e) || [];
          if (items.length != 1) {
            let customer = this.customer;
            let modal = this.modal.customer;
            for (let key in clipboard) {
              let value = clipboard[key];
              if (/name/.test(key)) value = this.TitleCase((value).replace(/\s+/g, ' ').trim());
              if (/first/.test(key)) customer.first_name = value;
              if (/last/.test(key)) customer.last_name = value;
              if (/phone/.test(key)) modal.phone = value;
              if (/email/.test(key)) modal.email = value;
            }
            let first_name = customer.first_name.split(' ');
            if (!customer.last_name && first_name.length >= 2) {
              customer.last_name = first_name.pop();
              customer.first_name = first_name.join(' ');
            }
            this.Create();
            this.$nextTick().then(() => {
              this.$refs.customer_first_name.blur();
            });
          } else {
            let customer = items[0];
            let lookup = {email: [], phone: []};
            for (let lookup_id of customer.customer_lookup_ids) {
              lookup[lookup_id.check_type == 'mal' ? 'email' : 'phone'].push(lookup_id.check_value);
            }
            if (lookup.email.includes(clipboard.email) || lookup.phone.includes(clipboard.phone_number)) {
              this.Edit(customer.id);
            }
          }
        }
        if (clipboard.search) {
          this.search.query = Object.values(clipboard.search)[0];
        }
      } else {
        this.cached = BPA.cache.local({name: this.$options.name, get: ['page', 'search']});
        for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
        this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'customer'})};
      }

      this.search.input = document.querySelector('.grid-search > input');

      await this.QueryCustomers();

      this.loading = false;
    },
    destroyed() {},
    computed: {
      main() {
        const main = {
          company: BPA.util.GetCompany(),
          view: document.querySelector('.content-scroll')
        }
        main.view.scroll = {top: 0};
        return main;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        //this.state = this.$t(this.Capitalize(this.search.state));
      }
    },
    methods: {
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      DateFormat(date) {
        if (date) return Tool.DateFormat(date);
      },
      DateToISO(date) {
        if (date) return Tool.DateToISO(date);
      },
      DateToUTC(date = new Date()) {
        if (date) return Tool.DateToUTC(date);
      },
      DateOnly(date) {
        date = date + ' 00:00:00';
        date = Tool.DateFormat(date).replace(Tool.TimeOnly(date), '');
        return date.replace(/,(?=[^,]*$)/, '');
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      CurrencyFormat(number, minmax) {
        if (number) return Tool.CurrencyFormat(number, null, minmax);
      },
      CurrencyName(currency_code) {
        return Tool.CurrencyName(currency_code);
      },
      CountryName(country_code, locale) {
        return Tool.CountryName(country_code, locale);
      },
      LanguageName(country_code, locale) {
        return Tool.LanguageName(country_code, locale);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      Webshop(company_id, increment_id) {
        return Tool.Webshop(company_id, increment_id);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      Capitalize(string = '') {
        return String(Tool.Capitalize(string));
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      Trim(e) {
        if (typeof e == 'string') {
          return String(e).trim();
        }
        if (typeof e == 'object' && e.target) {
          const element = e.target;
          const prop = 'value' || 'textContent';
          const text = element[prop];
          element[prop] = String(text).trim();
          return element[prop];
        }
      },
      CapitalizeFirstLetter(e) {
        if (typeof e == 'string') {
          return String(e.charAt(0).toUpperCase() + e.slice(1)).replace(/\s\s+/g, ' ');
        }
        if (typeof e == 'object' && e.target) {
          const element = e.target;
          const prop = 'value' || 'textContent';
          const text = element[prop];
          element[prop] = String(text.charAt(0).toUpperCase() + text.slice(1)).replace(/\s\s+/g, ' ');
          return element[prop];
        }
      },
      FormatText(html) {
        let value = (html).replace(/<br>/g, '\n').replace(/&nbsp;/g, ' ');
        let lines = value.split('\n');
        let text = [];
        for (let i = 0; i < lines.length; i++) {
          let line = lines[i].replace(/\s+/g, ' ').trim();
          if (!i) line = this.Capitalize(line);
          text.push(line);
        }
        return text.join('\n').replace(/\n+/g, '<br>').replace(/\s+/g, '&nbsp;');
      },
      FitToContent(event) {
        let input = event.target;
        input.style.height = '';
        input.style.height = `${input.scrollHeight + 2}px`;
      },
      ParcelOrder(order_id = '') {
        return window.location.origin + '/parcels/orders?id=' + order_id;
      },
      GetCompanyById(company_id) {
        return this.companies[company_id];
      },
      OpenModal(props = {}) {
        let modal = this.modal;
        if (!props.name || !props.mode) return;
        modal.customer.mode = props.mode;
        if (props.mode == 'create') {
          this.$nextTick().then(() => {
            this.$refs.customer_first_name.focus();
          });
        }
        /*
        if (props.mode == 'edit') {
          console.log(modal.customer)
        }
        */
        modal[props.name].open = true;
      },
      async Search() {
        this.loading = true;
        this.search.input.blur();
        this.search.query = this.search.query.replace(/\s\s+/g, ' ').trim();
        if (this.search.query == this.search.initial) return;
        this.page.current = 1;
        await this.QueryCustomers();
        this.loading = false;
      },
      async QueryCustomers() {
        let request = {
          query: this.search.query,
          page_offset: this.page.current
        }
        let list = await this.SearchCustomers(request);
        if (!list) {
          this.entries = 0;
          this.list.items = [];
          this.page.current = 1;
          this.page.number = 1;
          this.page.last = 1;
          return;
        }
        this.entries = list.count;
        this.list.items = list.result;
        this.page.last = list.page_count || 1;
        this.page.current = list.page_offset + 1;
        this.list.items.sort((a, b) => b.id - a.id);
        for (let item of this.list.items) {
          let lookup = item.customer_lookup_ids;
          delete item.customer_lookup_ids;
          item.lookup = {
            phone: lookup.filter(o => o.check_type == 'tlf').sort((a, b) => b.id - a.id),
            email: lookup.filter(o => o.check_type == 'mal').sort((a, b) => b.id - a.id)
          }
          item.note_type = (item.last_note || {}).note_type;
          item.note_value = (item.last_note || {}).note_value;
        }
        BPA.cache.local({name: this.$options.name, set: {page: this.page, search: this.search}});
        await this.$nextTick().then(() => {
          this.clickables = document.querySelectorAll('.clickable');
          let clipboard = JSON.parse(this.clipboard || '{}');
          if (Object.keys(this.cached.customer || {}).id) {
            this.Edit(this.cached.customer.id);
          } else if (this.list.items.length == 1 && clipboard.search) {
            this.Edit(this.list.items[0].id);
          }
          this.SetPageJumpWidth();
        });
      },
      HandleName(type = '') {
        if (!/first|last/.test(type)) return;
        let customer = this.customer;
        let trim_name = name => (name).replace(/\s+/g, ' ').trim();
        let first_name = trim_name(customer.first_name);
        let last_name = trim_name(customer.last_name);
        //let full_name = trim_name(first_name + ' ' + last_name).split(' ');
        //let name_prefixes = ['al', 'da', 'de', 'di', 'el', 'der', 'von', 'van'];
        customer.first_name = this.TitleCase(first_name);
        customer.last_name = this.TitleCase(last_name);
      },
      HandlePaste(e) {
        let text = e.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
      },
      HandleTextArea(e) {
        if (e.type == 'click') {
          if (e.target.classList.contains('v-wrapper')) {
            this.$refs.customer_note.focus();
          }
        }
        if (e.type == 'keydown') {
          if (e.target.contentEditable) {
            if (e.code == 'Enter') {
              let text = e.target.innerHTML.replace('<br>', '').split('<br>');
              text = text.filter(line => line).join('');
              if (!text) return e.preventDefault();
              if (e.getModifierState('Control')) {
                e.target.blur(); this.AddNote();
              }
            }            
          }
        }
      },
      async ToggleSwitch(item = {}, event = {}) {
        let input = event.target;
        let customer_id = item.id;
        let auto_lock_orders = item.auto_lock_orders;
        let action = `${input.checked ? 'en' : 'dis'}able`;
        if (this.modal.customer.open) {
          return item.auto_lock_orders = input.checked;
        }
        if (!await this.Prompt({
          approve: `Yes, ${action} it`,
          message: `${this.Capitalize(action)}s order auto-lock for the current customer`,
          type: input.checked ? 'danger' : 'success'
        })) return input.checked = auto_lock_orders;
        this.loading = true;
        auto_lock_orders = input.checked;
        await this.UpdateCustomer({customer_id, body: {auto_lock_orders}});
        item.auto_lock_orders = auto_lock_orders;
        this.loading = false;
      },
      HandleBlur(type = '') {
        let input = this.$refs[`customer_${type}`] || {};
        let modal = this.modal.customer;
        let value = String(input.value);
        let customer = this.customer;
        let lookup = customer.lookup;
        switch (type) {
          case 'phone': {
            value = value.replace(/(?!^\+)\D|\s/g, '');
            break;
          }
          case 'email': {
            value = value.replace(/\s/g, '');
          }
        }
        if (!value && lookup.length) {
          let check_value = '';
          let check_type = modal.lookup(type);
          for (let i = 0; i < lookup.length; i++) {
            if (lookup[i].check_type == check_type) {
              check_value = lookup[i].check_value;
              lookup.splice(i, 1);
              break;
            }
          }
          value = check_value;
        }
        modal[type] = value;
      },
      AddLookup(type = '') {
        if (!/phone|email/.test(type)) return;
        let customer = this.customer;
        let modal = this.modal.customer;
        let check_type = modal.lookup(type);
        let check_value = (modal[type]).replace(/\s/g, '').toLowerCase();
        let input = this.$refs[`customer_${type}`];
        let pattern = modal.regex[type];
        if (!check_value) return input.focus();
        if (!pattern.test(check_value)) return input.focus();
        if (customer.lookup.some(item => item.check_type == check_type && item.check_value == check_value)) return input.focus(); modal[type] = '';
        let customer_data_lookup = (customer.data.customer_lookup_ids || []).find(item => item.check_type == check_type && item.check_value == check_value);
        let customer_lookup = {check_type, check_value};
        if (customer_data_lookup) customer_lookup.id = customer_data_lookup.id;
        customer.lookup.unshift(customer_lookup);
        input.focus();
        //console.log(this.CloneObject(customer))
      },
      RemoveLookup(type = '', index = false) {
        if (!/phone|email/.test(type)) return;
        let customer = this.customer;
        let modal = this.modal.customer;
        let check_type = modal.lookup(type);
        let input = this.$refs[`customer_${type}`];
        let lookup = {phone: [], email: []};
        if (index === false) {
          customer.lookup.filter(item => item.check_type != check_type);
        } else {
          for (let type in lookup) {
            lookup[type] = customer.lookup.filter(item => item.check_type == modal.lookup(type));
          }
          lookup[type].splice(index, 1);
          customer.lookup = [...lookup.phone, ...lookup.email];
        }
        if (!input.value) input.focus();
        //console.log(this.CloneObject(customer))
      },
      AddNote() {
        let customer = this.customer;
        let modal = this.modal.customer;
        let textarea = this.$refs.customer_note;
        modal.note = (modal.note).replace(/<br>/g, '\n').replace(/&nbsp;/g, ' ').trim();
        if (!modal.note) return textarea.focus();
        let note_type = modal.type;
        let note_value = modal.note;
        let created_by = this.user.display_name;
        let created_at = this.DateToUTC();
        let customer_data_note = (customer.data.customer_note_ids || []).find(item => item.note_type == note_type && item.note_value == note_value);
        let customer_note = {note_type, note_value, created_by, created_at};
        if (customer_data_note) customer_note.res_user_id = customer_data_note.res_user_id;
        customer.note.unshift(customer_note);
        textarea.innerHTML = '';
        modal.tab = 'notes';
        textarea.focus();
        modal.note = '';
        //console.log(this.CloneObject(customer))
      },
      RemoveNote(index = false) {
        let customer = this.customer;
        if (index === false) {
          customer.note = [];
        } else {
          customer.note.splice(index, 1);
        }
        //console.log(this.CloneObject(customer))
      },
      async GetOrders() {
        let customer = this.customer;
        let modal = this.modal.customer;
        if (customer.order.length) {
          return modal.tab = 'orders';
        }
        this.loading = true;
        let orders = await this.GetCustomerOrders(this.customer.id);
        for (let order of orders) {
          order.webshop = this.Webshop(order.company_id, order.increment_id);
        }
        orders.sort((a, b) => {
          a = a.company_id + ':' + a.increment_id;
          b = b.company_id + ':' + b.increment_id;
          return b < a ? -1 : b > a ? 1 : 0;
        });
        customer.order = orders;
        this.$nextTick().then(() => {
          modal.tab = 'orders';
          this.loading = false;
        });
      },
      async Prompt(props = {}) {
        let setup = {...{disapprove: 'No'}, ...props};
        return await new Promise((resolve, reject) => {
          this.$eventHub.$emit('ValidateModalStart', setup);
          this.$eventHub.$on('ValidateModalStop', confirm => {
            this.$eventHub.$off('ValidateModalStop');
            if (!confirm) return reject();
            resolve(true);
          });
        }).catch(e => e);
      },
      async Create() {
        this.OpenModal({name: 'customer', mode: 'create'});
      },
      async Edit(customer_id = '') {
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].dataset.id == customer_id) {
            this.clickables[i].classList.add('selected');
            if (this.cached.customer && Object.keys(this.cached.customer).length) {
              this.clickables[i].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
            }
            break;
          }
        }
        this.loading = true;
        let customer = await this.GetCustomer(customer_id);
        if (!customer) return this.loading = false;
        let modal = this.modal.customer;
        let check = {phone: [], email: []};
        let lookup = customer.customer_lookup_ids;
        let note = customer.customer_note_ids;
        let user_ids = customer.res_user_ids;
        let data = this.CloneObject(customer);
        delete customer.customer_lookup_ids;
        delete customer.customer_note_ids;
        delete customer.res_user_ids;
        for (let item of lookup) {
          let type = modal.lookup(item.check_type);
          check[type].push(item);
          check[type].sort((a, b) => b.id - a.id);
        }
        for (let type in check) {
          if (check[type].length && !modal[type]) {
            modal[type] = check[type][0].check_value;
            for (let i = 0; i < lookup.length; i++) {
              if (lookup[i].id == check[type][0].id) {
                lookup.splice(i, 1);
                break;
              }
            }
          }
        }
        for (let item of note) {
          item.created_by = (user_ids.find(user => user.id == item.res_user_id) || {}).name;
          item.created_at = item.create_date;
          delete item.create_date;
        }
        note.sort((a, b) => {
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return b < a ? -1 : b > a ? 1 : 0;
        });
        BPA.cache.session({name: this.$options.name, set: {customer}});
        this.customer = {...customer, ...{lookup, order: [], note, data}};
        this.modal.customer = {...this.modal.customer, ...modal};
        this.OpenModal({name: 'customer', mode: 'edit'});
        this.loading = false;
        //console.log('edit', this.CloneObject(this.customer))
      },
      async Delete(customer_id = '') {
        if (!await this.Prompt({
          approve: 'Yes, delete it',
          message: 'Deletes the current customer',
          type: 'danger'
        })) return;
        this.loading = true;
        await this.DeleteCustomer(customer_id);
        this.$eventHub.$emit('ShowMessages', {
          message: 'Customer successfully deleted', 
          type: 'success', 
          hide: 2000
        });
        if (this.modal.customer.mode == 'edit') {
          this.$eventHub.$emit('CloseModal', 'customer');
        }
        await this.QueryCustomers();
        this.loading = false;
      },
      async Submit() {
        let required = [...document.querySelectorAll('[required]')];
        let invalid = required.some(input => !input.checkValidity());
        if (invalid) return this.$refs.customer_submit.click();
        let modal = this.modal.customer;
        let customer = this.CloneObject(this.customer);
        let lookup = {phone: modal.phone, email: modal.email};
        let customer_id = customer.id;
        let pattern = modal.regex;
        let note = customer.note;
        let data = customer.data;
        delete customer.note;
        delete customer.data;
        let prompt = false;
        let query = true;
        for (let type in lookup) {
          let check_value = lookup[type];
          if (pattern[type].test(check_value)) {
            let check_type = modal.lookup(type);
            if (!customer.lookup.some(item => Object.keys(item).some(key => item[key] == check_value))) {
              let customer_data_lookup = (data.customer_lookup_ids || []).find(item => item.check_type == check_type && item.check_value == check_value);
              let customer_lookup = {check_type, check_value};
              if (customer_data_lookup) customer_lookup.id = customer_data_lookup.id;
              customer.lookup.unshift(customer_lookup);
            }
          }
        }
        if (modal.note) {
          if (!note.some(item => Object.keys(item).some(key => item[key] == modal.note))) {
            let customer_data_note = (data.customer_lookup_ids || []).find(item => item.note_type == modal.type && item.note_value == modal.note);
            let customer_note = {note_type: modal.type, note_value: modal.note};
            if (customer_data_note) customer_note.res_user_id = customer_data_note.res_user_id;
            note.unshift(customer_note);
          }
        }
        for (let item of note) {
          if (!item.res_user_id) {
            for (let key in item) {
              if (!/type|value/.test(key)) {
                delete item[key];
              } else if (/value/.test(key)) {
                item[key] = (item[key]).replace(/<br>/g, '\n').replace(/&nbsp;/g, ' ');
              }
            }
          }
        }
        if (modal.mode == 'create') {
          if (!await this.Prompt({
            approve: 'Yes, create it',
            message: 'Creates the new customer',
            type: 'success'
          })) return;
          this.loading = true;
          customer_id = await this.CreateCustomer(customer);
          if (!customer_id) return this.loading = false;
          for (let item of note) {
            await this.AddCustomerNote({customer_id, body: item});
          }
          this.$eventHub.$emit('ShowMessages', {
            message: 'Customer successfully created', 
            type: 'success', 
            hide: 2000
          });
        }
        if (modal.mode == 'edit') {
          let update = {
            auto_lock_orders: customer.auto_lock_orders != data.auto_lock_orders && String(customer.auto_lock_orders),
            first_name: customer.first_name != data.first_name && customer.first_name,
            last_name: customer.last_name != data.last_name && customer.last_name
          }
          for (let key in update) {
            !update[key] && delete update[key];
            if (/true|false/.test(update[key])) {
              update[key] = update[key] == 'true';
            }
          }
          let add = {
            lookup: customer.lookup.filter(item => !item.id),
            note: note.filter(item => !item.res_user_id)
          }
          let remove = {
            lookup: (data.customer_lookup_ids || []).filter(item => !customer.lookup.some(e => e.id == item.id))
          }
          for (let prop of [update, add, remove]) {
            switch (prop) {
              case update: {
                prompt = Object.values(prop).length;
                break;
              }
              case add: {
                prompt = Object.keys(prop).some(key => prop[key].length);
                break;
              }
              case remove: {
                prompt = prop.lookup.length;
              }
            }
            if (prompt) break;
          }
          if (prompt) {
            if (!await this.Prompt({
              approve: 'Yes, update it',
              message: 'Updates the current customer',
              type: 'success'
            })) {
              return query = false;
            }
            query = true;
          } else {
            query = false;
          }
          this.loading = true;
          if (Object.values(update).length) {
            await this.UpdateCustomer({customer_id, body: update});
          }
          for (let type in add) {
            if (add[type].length) {
              for (let item of add[type]) {
                let Type = this.Capitalize(type);
                await this[`AddCustomer${Type}`]({customer_id, body: item});
              }
            }
          }
          for (let item of remove.lookup) {
            await this.DeleteCustomerLookup(item.id);
          }
        }
        if (query) {
          this.$eventHub.$emit('ShowMessages', {
            message: `Customer successfully ${modal.mode == 'create' ? 'created' : 'updated'}`, 
            type: 'success', 
            hide: 2000
          });
          await this.QueryCustomers();
        } else {
          this.$eventHub.$emit('ShowMessages', {
            message: `No changes made to the customer ID ${customer_id}`,
            type: 'warning',
            hide: 2000
          });
        }
        this.$eventHub.$emit('CloseModal', 'customer');
        this.loading = false;
      },
      async GetCustomer(customer_id = '') {
        return await BPA.api.GetCustomer(customer_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async GetCustomerOrders(customer_id = '') {
        return await BPA.api.GetCustomerOrders(customer_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async SearchCustomers(request = {}) {
        return await BPA.api.SearchCustomers(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async FindCustomerByLookup(request = {}) {
        return await BPA.api.FindCustomerByLookup(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async CreateCustomer(request = {}) {
        return await BPA.api.CreateCustomer(request).then(response => {
          return BPA.api.response({response, return: 'text'});
        }).then(response => {
          if (!response.ok) return;
          let text = response.result;
          if (isNaN(text)) {
            let match = text.match(/ tlf | mal /);
            if (match) {
              match = match[0].replace(/\s/g, '');
              let type = this.modal.customer.lookup(match);
              text = text.replace(match, type);
            }
            this.$eventHub.$emit('ShowMessages', {
              message: text, type: 'error', close: true
            });
            return null;
          }
          return text;
        }).catch(e => e);
      },
      async UpdateCustomer(request = {}) {
        return await BPA.api.UpdateCustomer(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async AddCustomerLookup(request = {}) {
        return await BPA.api.AddCustomerLookup(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async AddCustomerNote(request = {}) {
        return await BPA.api.AddCustomerNote(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async DeleteCustomerLookup(lookup_id = '') {
        return await BPA.api.DeleteCustomerLookup(lookup_id).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async DeleteCustomer(customer_id = '') {
        return await BPA.api.DeleteCustomer(customer_id).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      PageController(next_page) {
        let load_page;
        const page = {};
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (this.page.current != this.page.last) {
            this.page.current++;
            load_page = true;
            if (this.page.current == this.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (this.page.current > 1) {
            this.page.current--;
            load_page = true;
            if (this.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.QueryCustomers();
        }
      },
      ClickOpen(e) {
        if (!e) return;
        let row = {};
        let elm = e.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = ['actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.type == 'checkbox') return;
        if (/label|checkbox/.test(elm.className)) return;
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (filter.some(x => new RegExp(x).test(elm.className))) return;
        if (e.type == 'mousedown') {
          mousedown(e.which == 1);
        }
        if (e.type == 'mousemove') {
          mousedown(false);
        }
        if (e.type == 'mouseup') {
          if (mousedown()) {
            this.Edit(row.dataset.id);
          }
          mousedown(false);
        }
      },
      PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != this.page.current) {
            this.page.current = page.number;
            this.QueryCustomers();
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .button {
    &-group {
      display: flex;
      margin-left: 30px;
      align-items: flex-end;
      justify-content: flex-end;

      .separator {
        border-width: 0;
        border-style: dashed;
        border-color: #eaeaea;

        &.left {
          padding-left: 30px;
          border-left-width: 2px;
        }

        &.right {
          padding-right: 30px;
          border-right-width: 2px;
        }
      }

      * {
        min-width: 84px;

        & ~ * {
          margin-left: 30px;
        }
      }
    }
  }

  tbody:not(:empty) {
    tr {
      //vertical-align: top;
    }
    &::after {
      width: 100%;
      height: 1px;
      bottom: 0;
      //content: '';
      display: table-row;
      position: sticky;
      background: #cccccc;
    }
  }
</style>
